$base-font-size: 18px;
$base-line-height: 27px;

@import "./_reset.scss";
@import "node_modules/libsass-compass-vertical-rhythm/lib/compass_rhythm";
@include establish-baseline();


html {
    box-sizing: border-box;
    padding: 0;
}

* {
    box-sizing: inherit;
}

a {
    color: inherit;
    text-decoration: none;
}

strong {
    font-weight: bold;
}

img {
    display: block;
    max-width: 100%;
}

body {
    font-family: "IBM Plex Sans", sans-serif;
    background: #f1f3f5;
    margin: 0;
}

header {
    background: #212529;
    color: white;

    .content {
        width: 100%;
        max-width: rhythm(30);
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    h1.logo a {
        display: block;
        font-weight: 900;
        overflow: hidden;
        text-indent: -100%;
        background: url(logo-white.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: rhythm(10);
        height: rhythm(1);
        margin: rhythm(.5);
    }

    #menu {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        a {
            display: block;
            padding: rhythm(.5);

            &.active {
                background-color: #0c8599;
            }

            &:hover {
                text-decoration: underline;
            }

            i {
                margin-right: .5em;
            }
        }
    }
}

section {
    width: rhythm(30);
    background: white;
    margin: 0 auto;
    padding: rhythm(1);
    padding-top: 0;
    
    h1 {
        font-weight: bold;
        @include adjust-font-size-to($base-font-size * 1.5, 1);
    }
}


.toolbar {
    display: flex;
    align-items: center;
    padding: rhythm(1) 0 rhythm(2);

    h1, .meta {
        flex-grow: 1;
        margin-right: 1em;
    }

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: rhythm(3/*, $base-font-size / 1.5*/);
        height: rhythm(3/*, $base-font-size / 1.5*/);
        text-align: center;
        background: white;
        margin-right: rhythm(.5);
        flex-shrink: 0;

        &:hover {
            background: #e3fafc;
        }

        &.cta {
            background: #0b7285;
            color: white;

            &:hover {
                background: #0c8599;
            }
        }

        i {
            display: block;
            @include adjust-font-size-to($base-font-size);
        }

        
        .title {
            display: block;
            @include adjust-font-size-to($base-font-size / 1.5, .5);
        }
    }
}

.intro {
    margin-bottom: rhythm(2);
}
.details {
    margin-top: rhythm(-1);
    font-style: italic;
    margin-bottom: rhythm(2);
}

section.ui-message {
    box-shadow: inset 0 0 0 rhythm(.25) #82c91e;
    margin-top: rhythm(.5);
    margin-bottom: rhythm(.5);
    padding: rhythm(1);

    &.error {
        box-shadow: inset 0 0 0 rhythm(.25) #fd7e14;
    }
}


/*
Inbox
*/

.inbox {
    .message {
        display: grid;
        background: #f8f9fa;
        padding: rhythm(.5);

        &.unread {
            background: #e3fafc;
        }

        &.read {
            color: #868e96;
        }

        &:hover {
            .text {
                text-decoration: underline;
            }
        }

        &+ .message {
            box-shadow: inset 0px 1px #adb5bd;
        }

        .date {
            font-size: $base-font-size * 0.75;
        }

        .from {
            font-style: italic;
        }

        .text {
            font-weight: bold;
        }
    }
}


/*
Message view
*/
section.message {
    .meta .date {
        @include adjust-font-size-to($base-font-size / 1.5);
    }

    .message {
        background: #f1f3f5;
        padding: rhythm(.5);
        margin: rhythm(1) 0;
    }

    h2 {
        @include adjust-font-size-to($base-font-size, 2);
        font-weight: bold;
    }

    .file {
        display: flex;
        align-items: center;
        padding: rhythm(.5) 0;

        .preview {
            display: block;
            width: rhythm(3);
            height: rhythm(3);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.125), inset 0 0 16px rgba(0, 0, 0, 0.125);

            i.fa {
                @include adjust-font-size-to($base-font-size * 1.25, 1);
                color: #212529;
            }
        }

        .name {
            margin-left: rhythm(1);
        }

        &:hover .name {
            text-decoration: underline;
        }
    }
}


/*
Compose
*/

form {
    display: flex;
    flex-direction: column;

    a {
        color: #22b8cf;

        &:hover {
            text-decoration: underline;
        }
    }

    label.label {
        display: inline-block;
        color: #868e96;
        min-width: rhythm(2);
        margin-right: rhythm(1);
    }

    .to-group {
        display: flex;
        select {
            font: inherit;
            font-weight: bold;
            background: none;
            border: none;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .autocomplete {
            flex-grow: 1;
        }

        margin-bottom: rhythm(1);
    }

    .label-group {
        display: flex;
        align-items: center;

        input {
            flex-grow: 1;
        }

        margin-bottom: rhythm(1);
    }

    .checkbox-group {
        input {
            display: none;
        }

        label {
            cursor: pointer;

            &:hover {
                color: #212529;
            }
        }
        
        input + label i.yas {
            display: none;
        }
        input:checked + label i.yas {
            display: inline-block;
        }
        input + label i.nuh {
            display: inline-block;
            color: #adb5bd;
        }
        input:checked + label i.nuh {
            display: none;
        }
    }

    input, textarea {
        border: none;
        font: inherit;
        background: #f8f9fa;
        padding: rhythm(.25) rhythm(.5);
    }

    textarea {
        height: rhythm(5);
        padding: rhythm(.5);
        margin-bottom: rhythm(1);
    }

    .upload-group {
        margin-bottom: rhythm(1);
    }

    .button-container {
        display: flex;
        justify-content: flex-end;

        &> * {
            margin-left: rhythm(1);
        }
    }

    button, a.button {
        padding: rhythm(.5);
        background: #22b8cf /*Cyan 5*/;
        color: white;
        border: none;
        font: inherit;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: #3bc9db;
        }

        i {
            margin-right: rhythm(.5);
        }

        &.link {
            background: none;
            color: inherit;
        }
    }

    .spacer {
        height: rhythm(2);
    }
}

/*
User list
*/
section.users {
    a.user {
        display: flex;
        flex-direction: column;
        background: #f8f9fa;
        padding: rhythm(.5);

        .name {
            font-weight: bold;
        }

        .email {
            @include adjust-font-size-to($base-font-size/1.5);
        }

        &:hover .name {
            text-decoration: underline;
        }
    }
}

/*
Legal pages
*/
section.legal {
    h2 {
        @include adjust-font-size-to($base-font-size*1.5);
    }

    h3 {
        margin-top: rhythm(1);
        font-weight: bold;
    }

    a {
        color: #22b8cf;

        &:hover {
            text-decoration: underline;
        }
    }

    .intro {
        @include adjust-font-size-to($base-font-size/1.5);
        margin: 0;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: rhythm(1);
        margin: rhythm(1) 0;

        .card {
            display: flex;
            align-items: center;

            i {
                @include adjust-font-size-to($base-font-size * 1.5 * 1.5);
                margin-right: rhythm(1, $base-font-size * 1.5 * 1.5);
                color: #0b7285;
                width: rhythm(2, $base-font-size * 1.5 * 1.5);
                text-align: center;
            }

            h3 {
                margin: 0;
            }
        }
    }

    .box {
        margin: rhythm(1) 0;
        padding: rhythm(1);
        background: #f1f3f5;
    }
}


/*
Autocomplete component
*/

.autocomplete, .autocomplete-list {
    display: none;
}

.autocomplete-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.25);
}

.autocomplete-list > * {
    padding: .5em;
    min-width: 20em;
    max-width: 30em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    background: #f1f3f5;
}

.autocomplete-list > *:hover {
    background: #dee2e6;
}

.autocomplete-list > *.highlighted {
    background: #66d9e8;
}
